import React from "react"
import {
  PostCard,
  ContentContainer,
  BlogContent,
  Tag,
  Category,
  Seo,
} from "../components"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext
  const { totalCount } = data.postsRemark
  const allPosts = data.postsRemark.edges
  const posts = allPosts
  const allTags = data.tagsGroup.group
  const defaultImage = data.defaultImage.fluid

  const allCategories = data.categoriesGroup.group
  const categoryHeader = `CATEGORY: "${category}" (${totalCount})`

  return (
    <ContentContainer>
      <Seo
        title="Blog Posts"
        // image={seoImage}
        description="Page for guides, tutorial, or just notes."
      />
      <BlogContent>
        <div className="container">
          <section className="blog-content">
            <div className="col-md-12 tag-title-container">
              <h1>{categoryHeader}</h1>
            </div>
            {posts.map(({ node }, index) => (
              <PostCard
                key={index}
                to={node.frontmatter.slug}
                description={node.frontmatter.description}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                fluidImage={
                  node.frontmatter.image
                    ? node.frontmatter.image.childImageSharp.fluid
                    : defaultImage
                }
              />
            ))}
          </section>

          <section className="blog-sidebar">
            <div className="side-container">
              <h1>All Tags</h1>
              {allTags.map(node => (
                <Tag href={`/tags/${node.fieldValue}/`}>{node.fieldValue}</Tag>
              ))}
            </div>

            <div className="side-container">
              <h1>All Categories</h1>
              {allCategories.map(node => (
                <Category
                  href={`/categories/${node.fieldValue}/`}
                  count={node.totalCount}
                >
                  {node.fieldValue}
                </Category>
              ))}
            </div>
          </section>
        </div>
      </BlogContent>
    </ContentContainer>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query($category: String) {
    postsRemark: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { eq: $category } } }
      limit: 2000
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM YYYY")
            tags
            slug
          }
        }
      }
    }
    defaultImage: file(relativePath: { eq: "coding_default.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tagsGroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    categoriesGroup: allMdx(limit: 2000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`
